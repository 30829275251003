import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { Location } from "@reach/router"

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  const songDatas = data.allMarkdownRemark.edges

  return (
    <header
      style={{
        margin: "auto",
        maxWidth: 650,
        padding: `0 1rem`,
        textAlign: "center",
      }}
    >
      <Location>
        {({ location }) => {
          const songNameFull = location.pathname
          const parts = songNameFull.split("/")
          const songNamePart = parts[parts.length - 2]
          const songName = "/" + songNamePart + "/"

          return (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 999,
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <select
                onChange={evt => navigate(evt.target.value)}
                value={songName}
                style={{
                  width: "100vw",
                  height: "2rem",
                }}
              >
                {songDatas.map((s, i) => {
                  return (
                    <option value={s.node.fields.slug} key={s.node.id}>
                      {i} - {s.node.frontmatter.title}
                    </option>
                  )
                })}
              </select>
            </div>
          )
        }}
      </Location>
      <div style={{ paddingTop: "2.2rem", width: "100%" }}>{children}</div>
    </header>
  )
}
